import { forwardRef, memo, useEffect, useRef, useState } from "react";

import ReactDOM from "react-dom";
import invariant from "tiny-invariant";
import {
	attachClosestEdge,
	type Edge,
	extractClosestEdge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { DropIndicator } from "@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import {
	draggable,
	dropTargetForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { preserveOffsetOnSource } from "@atlaskit/pragmatic-drag-and-drop/element/preserve-offset-on-source";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import { dropTargetForExternal } from "@atlaskit/pragmatic-drag-and-drop/external/adapter";
import { token } from "@atlaskit/tokens";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import {
	Avatar,
	AvatarGroup,
	Box,
	Card,
	Divider,
	IconButton,
	Stack,
	SxProps,
	Theme,
	Typography,
} from "@mui/material";

import SourceOutlinedIcon from "@mui/icons-material/SourceOutlined";
import Country from "../../../../common/components/Country";
import {
	primaryColor,
	secondaryColor,
	textNeutralTertiary,
} from "../../../../core/theme";
import { CompactTender } from "../../tender.module";
import { useBoardContext } from "./board-context";
import { stringAvatar } from "../../utils/workflowView.util";
import { Link } from "react-router-dom";
import { If } from "../../../../common/components/If";
import TenderActionMenu from "./TenderActionMenu";

type State =
	| { type: "idle" }
	| { type: "preview"; container: HTMLElement; rect: DOMRect }
	| { type: "dragging" };

const idleState: State = { type: "idle" };
const draggingState: State = { type: "dragging" };

const style: { [key: string]: SxProps<Theme> } = {
	noMarginStyles: {
		margin: "space.0",
	},
	baseStyles: {
		width: "100%",
		minHeight: "128px",
		backgroundColor: "elevation.surface",
		borderRadius: "border.radius.200",
		position: "relative",
		padding: "10px 20px",
		":hover": {
			backgroundColor: "elevation.surface.hovered",
		},
	},
	buttonColumnStyles: {
		alignSelf: "start",
	},
	cardTitle: {
		color: primaryColor,
		textDecoration: "underline",
		fontSize: "16px",
		fontWeight: "700",
		lineHeight: "24px",
		textAlign: "left",
		marginBottom: "30px",
		wordBreak: "break-all",
	},
	cardDivider: {
		height: "20px",
		margin: "0 8px",
		borderRightWidth: 2,
	},
	cardInfo: {
		height: "20px",
		display: "flex",
		alignItems: "center",
		"& p, & svg, & span": {
			fontSize: "12px",
		},
	},
	footer: {
		margin: "8px 0",
		height: "30px",
		display: "flex",
		alignItems: "center",
		color: "#435C6E",
		"& p, & svg": {
			fontSize: "12px",
		},
	},
	footerDueDate: {
		backgroundColor: `var(--light-grey-grey-200, ${secondaryColor})`,
		display: "flex",
		alignItems: "center",
		borderRadius: "50px",
		padding: "2px 10px",
	},
	avatar: {
		width: "32px",
		height: "32px",
	},
};

const stateStyles: {
	[Key in State["type"]]: ReturnType<any> | undefined;
} = {
	idle: {
		cursor: "grab",
		boxShadow: "elevation.shadow.raised",
	},
	dragging: {
		opacity: 0.4,
		boxShadow: "elevation.shadow.raised",
	},
	// no shadow for preview - the platform will add it's own drop shadow
	preview: undefined,
};

type CardPrimitiveProps = {
	closestEdge: Edge | null;
	item: CompactTender;
	state: State;
};

const CardPrimitive = forwardRef<HTMLDivElement, CardPrimitiveProps>(
	function CardPrimitive({ closestEdge, item, state }, ref) {
		const {
			id,
			name,
			country_iso2_code: countryIso2Code,
			site_count: siteCount,
			direction,
			due_date: dueDate,
			trader,
			originator,
		} = item;

		return (
			<Card
				ref={ref}
				sx={{ ...style.baseStyles, ...stateStyles[state.type] }}
			>
				<Box display={"flex"} justifyContent="space-between">
					<Link to={`/tenders/${id}/my-pricings`}>
						<Typography
							sx={style.cardTitle}
							variant="h3"
							gutterBottom
							display={"flex"}
							alignItems={"center"}
						>
							{name}
						</Typography>
					</Link>
					<Box sx={style.noMarginStyles}>
						<TenderActionMenu tender={item} />
					</Box>
				</Box>
				<Box sx={style.cardInfo}>
					<Country country={countryIso2Code ? countryIso2Code : ""} />
					<Divider
						orientation="vertical"
						flexItem
						sx={style.cardDivider}
					/>

					<Typography
						component="span"
						display={"flex"}
						alignItems={"center"}
					>
						<Typography
							component="p"
							display={"flex"}
							alignItems={"center"}
							color={textNeutralTertiary}
						>
							#&nbsp;sites:
						</Typography>
						&nbsp;{siteCount}
					</Typography>
					<Divider
						orientation="vertical"
						flexItem
						sx={style.cardDivider}
					/>
					<Typography
						component="p"
						display={"flex"}
						alignItems={"center"}
					>
						{direction}
					</Typography>
					<Divider
						orientation="vertical"
						flexItem
						sx={style.cardDivider}
					/>
					<IconButton aria-label="delete">
						<SourceOutlinedIcon sx={{ color: "black" }} />
					</IconButton>
				</Box>
				<Box sx={style.footer} justifyContent={"space-between"}>
					<Box sx={style.footerDueDate}>
						<TimerOutlinedIcon />
						&nbsp;
						<Typography
							component="p"
							display={"flex"}
							alignItems={"center"}
						>
							{dueDate}
						</Typography>
					</Box>
					<Stack spacing={4}>
						<AvatarGroup spacing={15}>
							<If condition={trader}>
								<Avatar
									sizes="small"
									{...stringAvatar(
										`${trader?.first_name} ${trader?.last_name}`
									)}
								/>
							</If>
							<Avatar
								{...stringAvatar(
									`${originator?.first_name} ${originator?.last_name}`
								)}
							/>
						</AvatarGroup>
					</Stack>
				</Box>

				{closestEdge && (
					<DropIndicator
						edge={closestEdge}
						gap={token("space.100", "0")}
					/>
				)}
			</Card>
		);
	}
);

export const CustomCard = memo(function CustomCard({
	item,
}: {
	item: CompactTender;
}) {
	const ref = useRef<HTMLDivElement | null>(null);
	const { id } = item;
	const [closestEdge, setClosestEdge] = useState<Edge | null>(null);
	const [state, setState] = useState<State>(idleState);

	const { instanceId, registerCard } = useBoardContext();
	useEffect(() => {
		invariant(ref.current);
		return registerCard({
			cardId: id as number,
			entry: {
				element: ref.current,
			},
		});
	}, [registerCard, id]);

	useEffect(() => {
		const element = ref.current;
		invariant(element);
		return combine(
			draggable({
				element: element,
				getInitialData: () => ({
					type: "card",
					itemId: id,
					instanceId,
				}),
				onGenerateDragPreview: ({
					location,
					source,
					nativeSetDragImage,
				}) => {
					const rect = source.element.getBoundingClientRect();

					setCustomNativeDragPreview({
						nativeSetDragImage,
						getOffset: preserveOffsetOnSource({
							element,
							input: location.current.input,
						}),
						render({ container }) {
							setState({ type: "preview", container, rect });
							return () => setState(draggingState);
						},
					});
				},

				onDragStart: () => setState(draggingState),
				onDrop: () => setState(idleState),
			}),
			dropTargetForExternal({
				element: element,
			}),
			dropTargetForElements({
				element: element,
				canDrop: ({ source }) => {
					return (
						source.data.instanceId === instanceId &&
						source.data.type === "card"
					);
				},
				getIsSticky: () => true,
				getData: ({ input, element: e }) => {
					const data = { type: "card", itemId: id };

					return attachClosestEdge(data, {
						input,
						element: e,
						allowedEdges: ["top", "bottom"],
					});
				},
				onDragEnter: (args) => {
					if (args.source.data.itemId !== id) {
						setClosestEdge(extractClosestEdge(args.self.data));
					}
				},
				onDrag: (args) => {
					if (args.source.data.itemId !== id) {
						setClosestEdge(extractClosestEdge(args.self.data));
					}
				},
				onDragLeave: () => {
					setClosestEdge(null);
				},
				onDrop: () => {
					setClosestEdge(null);
				},
			})
		);
	}, [instanceId, item, id]);

	return (
		<Box sx={{ margin: "10px" }}>
			<CardPrimitive
				ref={ref}
				item={item}
				state={state}
				closestEdge={closestEdge}
			/>
			{state.type === "preview" &&
				ReactDOM.createPortal(
					<Box
						style={{
							boxSizing: "border-box",
							width: state.rect.width,
							height: state.rect.height,
						}}
					>
						<CardPrimitive
							item={item}
							state={state}
							closestEdge={null}
						/>
					</Box>,
					state.container
				)}
		</Box>
	);
});
